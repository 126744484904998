module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Francis Lamontagne - Designer / Développeur","short_name":"fld","start_url":"/","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"dab09ba2858130a80a80db203fa09635"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-80304242-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false,"exclude":["/30maps23","/5g","/abnb","/airesprotegees","/apropos","/anim","/anim2","/assnat","/auth","/battlecry","/budgetQc2024","/cadrefinancier","/canabis","/chine","/comparateur19","/comparateur21","/comparateur22","/content","/covidcanada","/danse","/dashboard","/dashboard2","/datavizcarto","/debat","/distanciation3","/dome-de-fer","/electeur","/electrification","/emoji","/enquete","/epicerie","/erable","/evolo","/femmes","/festivals","/feux","/feux24","/flipettes19","/flipettes21","/flipettes2022","/fraude","/gaza-mtl","/gaza","/geo","/inde","/index2","/indexL","/minorite","/musiquenoel","/neige","/paille","/panneau","/patrimoine","/polymetre","/pont","/pool22","/portfolio","/premierministre","/price","/proportionnel22","/pourboires","/pubfb","/reduflation","/resultatselection19","/resultats2022","/resultelect21","/revuepandemie","/robot","/rsi","/sante","/solitude","/swappy","/test","/test2","/testConsole","/ukraine","/ukraine2","/warhammer","/test","/test2","/theme","/exploration/boxAroundBoxes","/exploration/circleOfChaos","/exploration/dropABezier","/exploration/infiniteDancingSquare","/exploration/infiniteRandomCircle","/exploration/lineGeneration","/exploration/minimalLineGeneration","/exploration/outlinedBoxes","/exploration/responsiveArrowGrid","/exploration/screenClassPixels","/exploration/theSquareTunnel","/sandbox/sandboxIndex"],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
